import React from "react"
import Layout from "../components/Layout"
import Main from "../components/Main"

const UpdatesPage = () => {
  return (
    <Layout>
      <div className="bg-gradient-to-t from-zinc-50 to-zinc-300">
            <div className="max-w-4xl flex flex-col items-center mx-auto py-24 px-6">
                <h2 className="inline-block text-4xl pb-8 text-zinc-900 font-bold">
                    We're getting things ready
                </h2>
                <p className="text-zinc-800 font-medium text-lg pb-10">
                    We're getting the beta together, so sign up and we'll let you know as soon as its ready.
                    We'll only use your email to send you updates about the beta.
                </p>
                <div className="grid grid-rows-3 md:grid-cols-3 gap-4 w-full px-6">
                    <input type="text" placeholder="Email" className="col-span-2 appearance-none rounded ring-1 focus:outline-none focus:ring ring-purple-700 p-2 font-medium" />
                    <a href="" className="transition ease-in-out duration-200 p-4 bg-lime-700 text-white rounded shadow hover:bg-lime-600 text-center">
                        Sign up
                    </a>
                </div>
            </div>
        </div>
    </Layout>
  )
}
export default UpdatesPage;